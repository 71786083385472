import React, { ReactElement } from "react";
// import Image from "next/image";
import styles from "./styles.module.scss";
import FeatureList from "@components/shared/featureList";
import ScrollToTop from "@components/shared/scrollToTop";
import RearEnd from "@components/vehicleinjury/icons/RearEnd";
import HeadOn from "@components/vehicleinjury/icons/HeadOn";
import HitAndRun from "@components/vehicleinjury/icons/HitAndRun";
import Intersection from "@components/vehicleinjury/icons/Intersection";
import MultiVehicle from "@components/vehicleinjury/icons/MultiVehicle";
import Rollover from "@components/vehicleinjury/icons/Rollover";
import Sideswipe from "@components/vehicleinjury/icons/Sideswipe";
import TBone from "@components/vehicleinjury/icons/TBone";

export default function AccidentTypes(): ReactElement {
    const points = [
        {
            icon: <RearEnd />,
            description: "Rear-End Accidents",
        },
        {
            icon: <HeadOn />,
            description: "Head-On Collisions",
        },
        {
            icon: <HitAndRun />,
            description: "Hit-And-Run Accidents",
        },
        {
            icon: <Intersection />,
            description: "Intersection Accidents",
        },
        {
            icon: <MultiVehicle />,
            description: "Multi-Vehicle Accidents",
        },
        {
            icon: <Rollover />,
            description: "Rollover Accidents",
        },
        {
            icon: <Sideswipe />,
            description: "Sideswipe Accidents",
        },
        {
            icon: <TBone />,
            description: "T-Bone Accidents",
        },
    ];

    return (
        <section
            className={`max-w-7xl mx-auto md:pt-8`}
            style={{
                counterReset: "section",
            }}
        >
            <FeatureList
                featureList={points}
                mainTitle="What Are the Different Types of Car Accidents?"
                classes={{
                    mainTitleClasses: ` font-bold text-2xl text-center  lg:text-4xl mb-16`,
                    innerSectionClasses:
                        "grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 place-items-center gap-12 mt-[34px]",
                    oneBoxClasses: ` text-center  p-6  ${styles.item}`,
                    iconClasses:
                        "flex justify-center mx-auto block w-[fit-content]",

                    mainSectionClasses: "mx-auto mt-16",
                    descriptionClasses: "text-lg",
                }}
                colors={{
                    mainTitleColor: "#be1e20",
                }}
            />
            <div className="flex justify-center items-center mb-16  py-8 md:py-16">
                <ScrollToTop
                    colors={{
                        bg: "#be1e20",
                        text: "white",
                    }}
                    className="rounded"
                >
                    Get My Free Lawsuit Case Review
                </ScrollToTop>
            </div>
        </section>
    );
}
